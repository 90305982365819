.icon-fa-button {
  border-color: transparent;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  // box-shadow: #eee 0px 0px 2px;
  padding: 3px 8px;
  transition: box-shadow 0.3s ease;
  &:hover {
    cursor: pointer;
    box-shadow: #e2e2e2 0px 1px 5px;
  }
  &:active {
    cursor: pointer;
    background-color: #eee;
    box-shadow: #eee 0px 0px 0px;
  }
}
